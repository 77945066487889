import PostService from "./PostService";
export default class ClienteService {
  constructor(source) {
    this.source = source;
    this.postService = new PostService(this.source, true);
  }

  PegarUsuarioAtual(callback, callbackErro, ignoreCache) {
    let result = ignoreCache ? null : this._PegarUsuarioAtual();
    if (!result) {
      this.postService.Post(
        "/Pessoa/Pegar",
        { Includes: ["Parceiro"] },
        callback,
        callbackErro,
        true
      );
    } else {
      callback({
        Data: [result],
      });
    }
  }

  _PegarUsuarioAtual() {
    let target = this.source;
    while (target.$options.name !== "App" && target.$parent) {
      target = target.$parent;
    }
    if (target && target.$options.name === "App") {
      return target.PessoaLogada;
    }
  }

  Login(user, pass, callback, callbackErro) {
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("username", user);
    params.append("password", pass);
    this.postService.Post(
      "/Token",
      params,
      (response) => {
        this.SalvarToken(
          response.data.access_token,
          response.data.expires_in / 60
        );
        // eslint-disable-next-line
        callback(true);
      },
      callbackErro,
      true
    );
  }

  ListarPessoas(filtro, callback, callbackErro, hideLoading) {
    this.postService.Post(
      "/Pessoa/Listar",
      filtro,
      callback,
      callbackErro,
      hideLoading
    );
  }

  PegarCliente(pessoa, callback, callbackErro, hideLoading) {
    this.postService.Post(
      "/Pessoa/Pegar",
      pessoa,
      callback,
      callbackErro,
      hideLoading
    );
  }

  Gravar(cliente, callback, callbackErro, hideLoading) {
    let rq = {};
    Object.assign(rq, cliente);
    rq.Contato = cliente.Contato.filter((x) => x.Numero);
    let path = cliente.CNPJ ? "Pessoa/GravarPJ" : "Pessoa/GravarPF";
    if (cliente.IdPermissao === 4) {
      path += "Portal";
    }

    this.postService.Post(path, rq, callback, callbackErro, hideLoading);
  }

  ValidarCadastroCPF(cpf, callback, callbackErro) {
    this.postService.Post(
      "/Pessoa/ValidarCadastroCPF?cpf=" + cpf,
      null,
      callback,
      callbackErro,
      true
    );
  }

  Importar(formdata, callback) {
    this.postService.PostFormData(
      "/Pessoa/ImportarClientes",
      formdata,
      callback
    );
  }
}
